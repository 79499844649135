import useSWR, { mutate } from 'swr';
import { Address } from '@frontastic-engbers/types/account/Address';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { RefusedGift } from '@frontastic-engbers/types/cart/RefusedGift';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { CustomerType, ShippingType } from '@frontastic-engbers/types/engbers-custom';
import { fetchApiHub, revalidateOptions } from '@frontastic-engbers/lib';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
  comment?: string;
  shippingType?: ShippingType;
  customerType?: CustomerType;
};

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions);
};

export const checkout = async () => {
  const res = await fetchApiHub('/action/cart/checkout', {
    method: 'POST',
  });

  if ((res as Cart)?.cartId) {
    mutate('/action/cart/getCart', res);
  }

  return res;
};

export const orderHistory = async () => {
  return await fetchApiHub('/action/cart/getOrders');
};

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings');
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods');
};

export const getAvailableShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getAvailableShippingMethods');
};

export const addItem = async (variant: Variant, quantity: number, shouldMutate = true) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
      isGiftCard: variant.attributes.IsGiftcard,
    },
  };

  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );

  if (shouldMutate && (res as Cart)?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }

  return res;
};

export const removeItem = async (lineItemId: string, isCustom = false) => {
  const payload = {
    lineItem: { id: lineItemId },
    isCustom,
  };

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const changeItemVariant = async (lineItemId: string, variant: Variant, quantity: number, shouldMutate = true) => {
  const payload = {
    lineItem: {
      id: lineItemId,
    },
    variant: {
      sku: variant.sku,
      count: quantity,
    },
  };

  const res = await fetchApiHub(
    '/action/cart/changeLineItemVariant',
    {
      method: 'POST',
    },
    payload,
  );

  if (shouldMutate && (res as Cart)?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }

  return res;
}

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  };

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const redeemDiscountCode = async (code: string, pin: string) => {
  const payload = {
    code,
    pin,
  };
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );

  if ((res as Cart)?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }

  return res;
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const getLastUsedPaymentMethod = async (account: string): Promise<string> => {
  return await fetchApiHub('/action/cart/getLastUsedPaymentMethod', { method: 'POST' }, { accountId: account });
};

export const getOrder = async () => {
  return await fetchApiHub('/action/cart/getOrder');
};

export const undoRefuseGift = async (refusedGift: RefusedGift) => {
  const payload = {
    discountId: refusedGift.discount.id,
    sku: refusedGift.item?.variant?.sku,
  };

  const res = await fetchApiHub(
    '/action/cart/undoRefuseGift',
    {
      method: 'POST',
    },
    payload,
  );

  if ((res as Cart)?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }

  return res;
};
