import React, { useState } from 'react';
import {
  addAddress,
  changeEmail,
  changePassword,
  confirm,
  getAccount,
  login,
  logout,
  register,
  removeAddress,
  requestConfirmationEmail,
  requestPasswordReset,
  resetPassword,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  recheckSolvency,
  update,
  updateAccountWithWebservice,
  updateAddress,
} from '../../actions/account';
import {
  addItem,
  cartItems,
  checkout,
  getAvailableShippingMethods,
  getProjectSettings,
  getShippingMethods,
  orderHistory,
  redeemDiscountCode,
  removeDiscountCode,
  removeItem,
  changeItemVariant,
  setShippingMethod,
  undoRefuseGift,
  updateCart,
  updateItem,
} from '../../actions/cart';
import { addToWishlist, getWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist';
import { createSession } from '../../actions/adyen';
import { UseAccount } from './UseAccount';
import { UseCart } from './UseCart';
import { UseWishlist } from './UseWishlist';
import { UseAdyen } from './UseAdyen';
import { EcondaWidgetConfiguration, PageFolderTree, TBreadcrumb } from '@frontastic-engbers/types/engbers-custom';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  activeShops: {
    engbers: boolean;
    'engbers-xxl': boolean;
    'engbers-germany': boolean;
    'emilio-adani': boolean;
  };
  projectInfo: {
    name: string;
    projectId: string;
    siteUrl: string;
  };
  navigationTree: PageFolderTree | null;
  structuredBreadcrumb: [TBreadcrumb[], React.Dispatch<React.SetStateAction<TBreadcrumb[]>>];
}

export interface GlobalState {
  isFlyoutCartOpen: boolean;
  flyoutAlternativeProductsConfig?: (EcondaWidgetConfiguration & { sku?: string; categoryUrl?: string }) & Outfit;
}

export const getFrontasticState = (context?: 'pwa' | 'shop'): FrontasticState => {
  return {
    useCart: {
      // TODO PWA: This will break the PWA right now
      ...(context !== 'pwa' && cartItems()),
      addItem,
      updateCart,
      removeItem,
      changeItemVariant,
      updateItem,
      getShippingMethods,
      getAvailableShippingMethods,
      setShippingMethod,
      checkout,
      orderHistory,
      getProjectSettings,
      redeemDiscountCode,
      removeDiscountCode,
      undoRefuseGift,
    },
    useAccount: {
      // TODO PWA: This will break the PWA right now
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      changeEmail,
      recheckSolvency,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      updateAccountWithWebservice,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
    activeShops: {
      engbers: false,
      'engbers-xxl': false,
      'engbers-germany': false,
      'emilio-adani': false,
    },
    projectInfo: {
      name: '',
      projectId: '',
      siteUrl: '',
    },
    navigationTree: null,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    structuredBreadcrumb: useState<TBreadcrumb[]>([]),
  };
};
