import * as React from 'react';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { fetchApiHub, useAccount, useWishlist } from '@frontastic-engbers/lib';
import { LineItem } from '@frontastic-engbers/types/wishlist/LineItem';
import { IconCustom } from '@engbers/components';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { getVisitorId } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { Product } from '@frontastic-engbers/types/product/Product';
import styles from './wishlist-button.module.scss';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
export interface IWishlistButton {
  sku: string;
  wrapperClassName?: string;
  isCtaButton?: boolean;
  product?: Product;
  tabIndex?: number;
}
export const WishlistButton: React.FC<IWishlistButton> = ({
  sku,
  wrapperClassName,
  isCtaButton = false,
  product,
  tabIndex = 0
}) => {
  const {
    data,
    addToWishlist,
    removeLineItem
  } = useWishlist();
  const {
    account
  } = useAccount();
  const [productInWishlist, setProductInWishlist] = useState<LineItem>(null);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  useEffect(() => {
    if (sku && data?.lineItems?.length) {
      const productInWishlist = data.lineItems.find(({
        variant
      }) => variant.sku === sku);
      if (productInWishlist) {
        animateButton();
      }
      setProductInWishlist(productInWishlist);
    } else {
      setProductInWishlist(undefined);
    }
  }, [data?.lineItems, sku]);
  const animateButton = () => {
    const timeOut = setTimeout(() => {
      setIsAnimating(() => false);
      clearTimeout(timeOut);
    }, 1500);
    clearTimeout(timeOut);
    setIsAnimating(() => true);
  };
  const handleAddToWishlist = async () => {
    if (!product) {
      product = await fetchApiHub(`/action/product/getProduct?sku=${sku}`);
    }
    const currentVariant = product?.variants?.find(variant => variant.sku === sku);
    if (productInWishlist) {
      await removeLineItem(productInWishlist.lineItemId);
      return new TagManager().econdaWishlistEvent({
        id: sku.split(/[-.]/)[0],
        quantity: 1,
        date: new Date().toISOString().replace('T', ' ').split('.')[0]
      }, 'econdaRemoveFromWishlist', 'rmv_wish', getVisitorId(account)).executePush();
    }
    await addToWishlist(sku, 1);
    const tagManager = new TagManager();
    tagManager.econdaWishlistEvent({
      id: sku.split(/[-.]/)[0],
      quantity: 1,
      date: new Date().toISOString().replace('T', ' ').split('.')[0]
    }, 'econdaAddToWishlist', 'add_wish', getVisitorId(account)).executePush();
    if (product) {
      tagManager.addToWishlistEvent([ItemMapper.productToItem(product, 1, 0, currentVariant, false)], currentVariant.price).executePush();
    }
  };
  if (!sku) {
    return null;
  }
  return <div className={classnames(wrapperClassName, styles.wishlistWrap)} data-sentry-component="WishlistButton" data-sentry-source-file="index.tsx">
      <button type="button" className={classnames(styles.wishlistButton, {
      cta: isCtaButton,
      [styles.animateWishlistBtn]: isAnimating
    })} onClick={handleAddToWishlist} aria-label={formatMessage({
      id: 'ariaLabel.addToWishlist'
    })} tabIndex={tabIndex}>
        {productInWishlist ? <IconCustom icon="HeartFilled" color={isCtaButton ? 'white' : 'primary'} width={24} /> : <IconCustom icon="Heart" color={isCtaButton ? 'white' : 'primary'} width={24} />}
      </button>
    </div>;
};